import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, BreadCrumb, MarkdownContent, MarkdownContentWrapper, MarkdownContentWrapperInner, MarkdownContentTitle, ComingSoon } from '../components/Section'
import PrimaryLinkButton from '../components/Button/PrimaryLinkButton'
import { Link } from "gatsby"
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import BreakpointDown from '../components/Media/BreakpointDown'


const GroceryTitle = styled.h1`

`
const GroceryRow = styled.div`
  display:flex;
  margin-left:-15px;
  margin-right:-15px;
  flex-wrap:wrap;
  justify-content:space-between;
  margin-top:20px;
`
const GroceryColumn = styled.div`
 flex:0 0 50%;
 width:50%;
 padding-left:15px;
 padding-right:15px;
 ${BreakpointDown.xl`
  flex:0 0 100%;
  width:100%; 
 `}
 .gatsby-image-wrapper{ width:100%;}
 &:first-child{
   padding-right:60px;
   ${BreakpointDown.xl`
      flex:0 0 100%;
      width:100%;
      padding-right:15px;
    `}
 }
 &:last-child{
   padding-top:40px;
   ${BreakpointDown.md`
     padding-top:0px;
   `}
 }
`


// GroceryIndustry
const SectionBoxShadow = styled(Section)`
  box-shadow: 0px 20px 85px #00000033;
  z-index:2;
`
const GroceryIndustryTitle = styled.h2`
  max-width: 768px;
  text-align:center;
  margin-left:auto;
  margin-right:auto;
`

const GroceryIndustryList = styled.div`
 display:flex;
 flex-wrap:wrap;
 margin-top:60px;
 border-bottom:1px solid #CCCCCC;
 padding-bottom:60px;
 ${BreakpointDown.xl`
   margin-top:30px;
   padding-bottom:30px;
 `}
 &:nth-child(odd){
   flex-direction:row-reverse;
   &:last-child{border-bottom:0; padding-bottom:0;}
   .industry-left{
  &:before{
    right:auto;
    margin-right:0;
    left: 0;
    top: 35px;
    margin-left: -60px;
  }
   }
   .industry-right{
    padding-right: 90px;
    padding-left:0;
    ${BreakpointDown.xl`
      padding-right: 0px;
    `}
   }
 }
`
const GroceryIndustryListLeft = styled.div`
flex:0 0 600px;
position:relative;
${BreakpointDown.xl`
 flex:0 0 100%;
`}
&:before{
  content:'';
  width:120px;
  height:4px;
  background:#000;
  position:absolute;
  right:0;
  top:35px;
  margin-right:-60px;
  z-index:1;
  ${BreakpointDown.xl`
     display:none;
  `}
}
`
const GroceryIndustryListRight = styled.div`
  flex:1;
  padding-left:90px;
  padding-top:20px;
  ${BreakpointDown.xl`
    padding-left: 0px;
  `}
    ${BreakpointDown.md`
     padding-top:10px;
  `}
`
const GroceryIndustryListRightTitle = styled.div`
${BreakpointDown.md`
  margin-bottom:0;
`}
`
const GroceryIndustryListRightContent = styled.div`
 p{
   font-style:normal;
 }
`
const TraingleShape = styled.div`
  width: 0;
  height: 0;
  display: block;
  border-top: 20px transparent solid;
  border-right: 20px transparent solid;
  border-left: 20px transparent solid;
  border-bottom: 20px #fff solid;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  margin-left: auto;
  margin-right: auto;
  z-index: 3;
`



function BusinessTelephonePage() {
  return (
    <Layout pageName="technology">
      <SEO
        title="Business Phone Systems and Call Center Technology"
        description="WYN Technologies offers a cloud-based business phone system with high speed and flawless connectivity, suitable for call centers, businesses, and other institutions. Get in touch today."/>
      <Section pt="174px" pb="80px" bgColor="#F8F8F8" xpt="120px" xpb="60px" mpt="90px" mpb="40px">
        <TraingleShape />
        <div className="container">
          <BreadCrumb>
            <Link to="/">Home</Link><Link to="/#">Business Telephone Systems & Contact Center Equipment Solutions</Link>
          </BreadCrumb>
          <GroceryRow>
            <GroceryColumn>
              <GroceryTitle>Business Telephone Systems & Contact Center Equipment Solutions</GroceryTitle>
              <p>Searching for the best small business phone system? Did you realize that you can cut costs on an older analog or even a digital PBX system by moving to a cloud-based phone system? And here’s some more good news: <strong>WYN Technologies</strong> has over 20 VoIP phone system options we can offer you, so we can absolutely help you find the best business phone system to meet all of your needs.</p>

                <p>Or perhaps you have a customer contact center or call center that’s in need of some equipment upgrades. <strong>WYN</strong> has you covered there, too! Just connect with us today, we’re here to help!</p>
            </GroceryColumn>
            <GroceryColumn>
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Brands We Trust"
                placeholder="blurred"
              />
            </GroceryColumn>
          </GroceryRow>
        </div>
      </Section>
      <SectionBoxShadow bgColor="#F8F8F8" pt="80px" pb="80px" xpt="60px" xpb="60px" mpt="40px" mpb="40px">
        <div className="container">
          <GroceryIndustryTitle className="h1">What We Provide with Our Business Telephone System & Contact Center Services</GroceryIndustryTitle>
          <GroceryIndustryList>
              <GroceryIndustryListLeft className="industry-left">
                <StaticImage
                  src="../images/placholder.jpg"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Industry"
                  placeholder="blurred"
                />
              </GroceryIndustryListLeft>
              <GroceryIndustryListRight className="industry-right">
                <GroceryIndustryListRightTitle className="h3">Sales, Installation, & Product Orientation</GroceryIndustryListRightTitle>
                <GroceryIndustryListRightContent>
                  <p><strong>WYN</strong> is thrilled to serve as a preferred provider of exceptional hosted VoIP point system products from top makers like Yealink, Intermedia, Zultys, and more. And we’re much more than just a vendor; we install everything we sell, and our clients agree that our quality of installation workmanship is also exceptional. After we install your equipment, we won’t leave you guessing about how everything works, either. We’ll provide proper product orientation, and we’ll make sure you understand how to use it. And we’re not finished until we take the time to answer all questions you may have!</p>
                 {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
                </GroceryIndustryListRightContent>
              </GroceryIndustryListRight>
            </GroceryIndustryList>
          <GroceryIndustryList>
              <GroceryIndustryListLeft className="industry-left">
                <StaticImage
                  src="../images/placholder.jpg"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Industry"
                  placeholder="blurred"
                />
              </GroceryIndustryListLeft>
              <GroceryIndustryListRight className="industry-right">
                <GroceryIndustryListRightTitle className="h3">Site Surveys</GroceryIndustryListRightTitle>
                <GroceryIndustryListRightContent>
                  <p>Before we ever recommend a business telephone system or contact center solution, we’ll start by taking the time to have a real conversation with you. Once you connect with <strong>WYN</strong>, we’ll want to learn more about your business, your facility’s layout, and the goals you have for your business. We’ll then have one or more of our expert technicians come out and do an in-person, physical survey of your site. We’ll share our observations & learnings, and then we’ll give our recommendations for the best VoIP phone solution that will meet both your business needs and your allocated budget.</p>
                  {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
                </GroceryIndustryListRightContent>
              </GroceryIndustryListRight>
            </GroceryIndustryList>
          <GroceryIndustryList>
              <GroceryIndustryListLeft className="industry-left">
                <StaticImage
                  src="../images/placholder.jpg"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Industry"
                  placeholder="blurred"
                />
              </GroceryIndustryListLeft>
              <GroceryIndustryListRight className="industry-right">
                <GroceryIndustryListRightTitle className="h3">Free Product Demos</GroceryIndustryListRightTitle>
                <GroceryIndustryListRightContent>
                  <p>Like to learn more about the VoIP product solutions <strong>WYN</strong> can provide for your business? If you’re new to VoIP phones or would just like to learn more about how a VoIP system actually works, we’d be happy to take you through a product demo. We can illustrate many benefits and features of our VoIP phones, and we’ll walk you through the best ways to take full advantage of your business. At the end of the day, we’re not just a technology provider; we’re really here to help make your business better and ultimately more profitable!</p>
                  {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
                </GroceryIndustryListRightContent>
              </GroceryIndustryListRight>
            </GroceryIndustryList>
          <GroceryIndustryList>
              <GroceryIndustryListLeft className="industry-left">
                <StaticImage
                  src="../images/placholder.jpg"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Industry"
                  placeholder="blurred"
                />
              </GroceryIndustryListLeft>
              <GroceryIndustryListRight className="industry-right">
                <GroceryIndustryListRightTitle className="h3">Ongoing Support</GroceryIndustryListRightTitle>
                <GroceryIndustryListRightContent>
                <p>Some other technology vendors and installers tend to leave you to fend for yourself once your new business telephone solution has been implemented. Thankfully, <strong>WYN</strong> won’t leave you hanging! We’re happy to provide a “service after the sale” contract to keep everything maintained and working smoothly for you. That’s one less thing you need to worry about while running your business!</p>
               
                <p>And in any case, we’re here for you whenever you need us. If you ever experience a problem or an issue with your phone system, you can call us 24/7. We’re committed to taking care of you the right way!</p>
                 {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
                </GroceryIndustryListRightContent>
              </GroceryIndustryListRight>
            </GroceryIndustryList>
        </div>
      </SectionBoxShadow>
      <Section pt="100px" pb="100px" xpt="60px" xpb="60px" mpt="40px" mpb="40px" bgColor="#F8F8F8">
        <div className="container">
          <MarkdownContentWrapper>
            <MarkdownContentWrapperInner>
              {/* <MarkdownContentTitle>
                <h2 className="h1">Content Heading</h2>
              </MarkdownContentTitle>
              <ComingSoon textAlign="center">Coming Soon...</ComingSoon> */}
              <MarkdownContent>
                  <h2>Business Phone Systems & Solutions</h2>
                  <p>There are several different approaches to business phone systems in use today, each with its own set of pros and cons. Learn more about VoIP, PBX system, and cloud-based system approaches:</p>
                  <ul>
                  <li>
                    <strong>VoIP Phones</strong>
                    <p>Voice over internet protocol (VoIP) phone systems are one of the most popular business phone solutions in use today. VoIP phones operate over a broadband internet connection, rather than having to rely upon traditional landlines. As long as you’re able to maintain a secure internet connection, you can make and receive calls on a VoIP phone from basically anywhere!</p>
                    </li>
                  <li>
                    <strong>PBX Phone Systems</strong>
                    <p>Private Branch Exchange (or PBX) is a private phone network for your office. This type of system can include one or multiple lines, and can offer some of the same features as a VoIP system. Some of the downsides of a PBX approach are that you’ll enjoy fewer features, can have more hardware complications, and can potentially experience more downtime.</p>
                    </li>
                  <li>
                    <strong>Cloud-Based Phone Systems</strong>
                    <p>There are some nice benefits that go with choosing a cloud-hosted phone system. Your provider manages most of your hardware, so there’s less to worry about in-house. You pay one convenient monthly fee for your service, and the service is easily scalable. The downside is that you’re more or less completely reliant upon an off-site provider, and upfront costs for such a system tend to be a bit higher than other options.</p>
                    </li>
                    
                  </ul>
                </MarkdownContent>
              <MarkdownContent>
                  <h2>Some of the Many Features Available Through Modern Business Phone Systems</h2>
                <p>Leading and managing a business is hard enough in its own right, but choosing to install the right business communication system is one of the best ways to help yourself. WYN offers many advanced office phone system options which boast some pretty robust features, like:</p>
                <ul>
                  <li>Voicemail</li>
                  <li>Call Forwarding</li>
                  <li>On-Hold Music, or Specific Messaging</li>
                  <li>Conference Calls</li>
                  <li>Call Monitoring Services</li>
                  <li>Ability to Incorporate Multiple Phone Numbers & Lines</li>
                </ul>
                </MarkdownContent>
              <MarkdownContent>
                  <h2>Choosing the Right Business Phone System</h2>
                <p>Picking the right telephone system is an essential factor in the operation of your business. A phone system is a veritable lifeline for suppliers, customers, and employees in the working world. And thus, selecting the right phone system is paramount to streamlining communication.</p>
                <ul>
                  <li>
                    <strong>What Hardware You’ll Need</strong>
                    <p>One of the first things you will want to decide is how many devices and lines you’ll need to cover your staff - not just today, but years in the future. A two-line system may be enough for a few employees, but if your business grows, you may be forced to make costly upgrades to keep up.</p>
                  </li>
                  <li>
                    <strong>Necessary Features</strong>
                    <p>The next step will be to decide which features are the best fit for your company. Will you have receptionists or an auto-attendant to manage incoming calls? Is your business going to need call monitoring for quality assurance? Will you need your employees to operate wirelessly? Many factors will determine what best suits your business.</p>
                  </li>
                  <li>
                    <strong>Location</strong>
                    <p>You will need to decide whether your phone service will need to cover one or more locations. Some locations may require additional hardware or different types of systems to function correctly.</p>
                  </li>
                  </ul>
                </MarkdownContent>
              <MarkdownContent>
                  <h2>Contact Center Technologies</h2>
                <p>More and more businesses are opting to outsource their telephone services to third-party contact centers. Determining whether your business will need this type of service is a huge step, and you will likely want to take special care to find the right third-party service to fit your needs.</p>
                <p>Other factors may include the size of your business and expectations of growth. No one wants to purchase a business phone system only to discover it will require costly upgrades to cover your needs.</p>
                </MarkdownContent>
              <MarkdownContent>
                  <h2>Business Telephone Systems & Contact Centers at WYN Technologies</h2>
                <p>Selecting the right business telephone system or contact center can be a complicated process for any company. But WYN Technologies aims to streamline the process and provide the best business phone systems to fit any need. Whether you’re looking for a VOIP phone system with video conferencing and mobile functionality or a trusted contact center with auto attendants to handle your calls, we have the solution for you! Give us a call today at <a href="tel:336 8990555">(336) 899-0555</a> !</p>
                </MarkdownContent>
            </MarkdownContentWrapperInner>
          </MarkdownContentWrapper>
        </div>
      </Section>
    </Layout>
  )
}
export default BusinessTelephonePage
